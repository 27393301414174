import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthModel } from '../modules/auth/_models/auth.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // private fields
    constructor(private http:HttpClient){

    }
    getSingleJamaat(){
        const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${auth.accessToken}`,
      });
        return this.http.get<any>(environment.apiUrl+'/jamaat/check',{
            headers: httpHeaders,
          });
    }
    getMasjidList(){
      const auth = this.getAuthFromLocalStorage();
  if (!auth || !auth.accessToken) {
    return of(undefined);
  }
  const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.accessToken}`,
    });
      return this.http.get<any>(environment.apiUrl+'/users/masjidlist',{
          headers: httpHeaders,
        });
  }
  getSlot(masjid){
    const auth = this.getAuthFromLocalStorage();
if (!auth || !auth.accessToken) {
  return of(undefined);
}
const httpHeaders = new HttpHeaders({
    Authorization: `Bearer ${auth.accessToken}`,
  });
    return this.http.post<any>(environment.apiUrl+'/jamaat/slotList',{id:masjid},{
        headers: httpHeaders,
      });
}
    addJamaat(data){
        const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${auth.accessToken}`,
      });
        return this.http.post<any>(environment.apiUrl+'/jamaat',{data},{
            headers: httpHeaders,
          });
    }
    searchJamaat(data){
      const auth = this.getAuthFromLocalStorage();
  if (!auth || !auth.accessToken) {
    return of(undefined);
  }
  const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.accessToken}`,
    });
      return this.http.post<any>(environment.apiUrl+'/jamaat/search',{data},{
          headers: httpHeaders,
        });
  }
  searchJamaatName(data){
    const auth = this.getAuthFromLocalStorage();
if (!auth || !auth.accessToken) {
  return of(undefined);
}
const httpHeaders = new HttpHeaders({
    Authorization: `Bearer ${auth.accessToken}`,
  });
    return this.http.post<any>(environment.apiUrl+'/jamaat/name',{data},{
        headers: httpHeaders,
      });
}
    public getToken(): string {
        var data=JSON.parse(localStorage.getItem('v710demo1-authf649fc9a5f55'));
        console.log(data);
        
        return data.token;
      }
      private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

      private getAuthFromLocalStorage(): AuthModel {
        try {
          const authData = JSON.parse(
            localStorage.getItem(this.authLocalStorageToken)
          );
          return authData;
        } catch (error) {
          console.error(error);
          return undefined;
        }
      }

      addPerson(data){
        const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${auth.accessToken}`,
      });
        return this.http.post<any>(environment.apiUrl+'/jamaat/addPerson',{data},{
            headers: httpHeaders,
          });
    }
}
